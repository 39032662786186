

























































































































.balance-buttons{
  margin-left: 10px;
  font-size:13px !important;
}
.disabled-buttons{
  background-color: lightgrey;
  color: black;
}
.variation-button{
  margin-top: 50px;
  margin-left: 10px;
  font-size:13px !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  background-color: #51a351;
}
.table{
  margin-left: 10px;
}
#highchart-content {
  height: 330px;
  width: 100%;
}
hr{
  width: 50%;
}

