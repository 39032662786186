






































































































































.simulador-highchart{
  margin-left: 5%;
  width: 90%;
}

#highchart-content {
  height: 330px;
  width: 100%;
}
