

















































































































































































@use "@material/data-table/data-table";
@include data-table.core-styles;
.currency-hedge{
    width: 50%;
}
.currency-diversification{
    width: 50%;
}
h1{
    margin-left: 20%;
}

.mdc-data-table__header-cell,
.mdc-data-table__cell {
    color: rgba(0, 0, 0, 0.6);
    background-color: #F3F4F7;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;}
  
.mdc-data-table {
    margin-left: 5%;
    width: 75%;}
  
.mdc-data-table__row,
.mdc-data-table__cell {
    background-color: white;
    color: #B00020;}
.simulator-table {
    margin: 19px 44px 24px;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

input[type="number"] {
    min-width: 50px;
}


