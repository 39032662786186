


































































































































































































































@use "@material/data-table/data-table";
@include data-table.core-styles;

.mdc-data-table__header-cell,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.6);
  background-color: #F3F4F7;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;}

.mdc-data-table {
  width: 60%;}

.mdc-data-table__row,
.mdc-data-table__cell {
  background-color: white;
  color: #B00020;}
.simulator-table {
  margin: 19px 44px 24px;
}
.simulador-highchart {
  margin: 0 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.6);
  width: 70%;
}
.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}
.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  padding: 7px 25px;

  span {
    font-size: 18px;
  }
}
.text-bold {
  font-weight: bold;
}

.simulator-button-add {
  background-color: #00AEDD;
  border-radius: 50%;
  color: #FFFFFF;
  border: none;
  margin-left: 20px;
  padding: 3px 5px 1px;
  box-shadow: 0px 1px 3px gray;
  cursor: pointer;
}

.simulator-chips-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: 22px 10px 0;
}

.simulator-chips-box {
  display: flex;
  margin: 0 6px 12px;
  font-size: 14px;
  line-height: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.62);
  color: #000010;
  border-radius: 16px;
  padding: 5px 10px;
}

.mdc-button {
  margin-top: 20px;
}

.icon-delete {
  cursor: pointer;
  font-size: 20px;
  color: #606577;
  margin-left: 10px;
}

.simulador-button-save-index {
  text-align: center;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 20px 37px 43px;;
}

.simulator-title-index {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #0F0F1A;
  margin-left: 37px;
}

.simulator-chips-margin {
  margin: 5px 30px 30px;
}

.simulador-input-autocomplete {
  text-align: left;
  margin-left: 45px;
  width: 75%;
}

.label-autocomplete-input {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.38);
}
.control {
  margin: 0;
}

#highchart-content {
  height: 330px;
  width: 100%;
}
.highcharts-root {
  width: 380px !important;
}
#simulator{
    width: 40%;
}
